import { LoadingButton } from "@mui/lab";
import { Box, Container } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ReactComponent as SubscriptionErrorIcon } from "../../assets/icons/delete_account_popup.svg";
import { DrawerHeading } from "../../components/CustomSwipeableDrawer";
import { Layout } from "../../layout/Layout2";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  confirmPayment,
  executePaymentRequest,
} from "../../store/slices/payments";
import {
  ExecutePaymentRequestData,
  PaymentConfirmationRequestData,
} from "../../types/payments";
import { localStorageGet, localStorageSet } from "../../utils/localStorage";

const PaymentError: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();
  const [selectedOption, setSelectedOption] = useState<string>("knet");
  const [tryagain, setTryagain] = useState<boolean>(false);

  const isModeArabic = i18n.language === "ar";

  const { userData } = useAppSelector((state) => state.accounts);
  const {
    paymentConfirmationError,
    paymentConfirmationLoading,
    paymentConfirmationResponse,
    executePaymentLoading,
    executePaymentResponse,
    executePaymentError,
  } = useAppSelector((state) => state.payments);

  const paymentId = useCallback(() => {
    // Get the current URL
    const currentUrl = window.location.href;
    const searchParams = new URLSearchParams(new URL(currentUrl).search);
    return searchParams.get("paymentId");
  }, []);

  const id = paymentId();

  const params: PaymentConfirmationRequestData = {
    key: id as string,
    key_type: "PaymentId",
  };

  //   Get executeRequestPaymentData key from localStorage
  const paymentData = localStorageGet(
    "executeRequestPaymentData"
  ) as ExecutePaymentRequestData;

  const executeRequestPaymentData: ExecutePaymentRequestData = {
    restaurant_slug: paymentData?.restaurant_slug as string,
    package: {
      period: paymentData?.package?.period as number,
      off_days: paymentData?.package?.off_days,
      price: paymentData?.package?.price,
      discounted_price: paymentData?.package?.discounted_price,
      package_id: paymentData?.package?.package_id,
    },
    delivery_time: {
      id: paymentData?.delivery_time?.id as number,
      start_time: paymentData?.delivery_time?.start_time as string,
      end_time: paymentData?.delivery_time?.end_time as string,
    },

    plan: {
      title: paymentData?.plan?.title,
      title_arabic: paymentData?.plan?.title_arabic,
      plan_id: paymentData?.plan?.plan_id,
    },
    checkout_notes: paymentData?.checkout_notes,
    start_date: paymentData?.start_date,
    payment_method: selectedOption,
    call_back_url: paymentData?.call_back_url,
    error_url: paymentData?.error_url,
  };

  useEffect(() => {
    if (!userData || !id) return;

    dispatch(confirmPayment(userData?.token?.access as string, params));

    if (window.dataLayer.find((item) => item.id === id)) return;

    const paymentData = localStorageGet("executeRequestPaymentData");

    window.dataLayer.push({
      id,
      name: userData?.name,
      phone: userData?.phone_number,
      price: paymentData.package.discounted_price || paymentData.package.price,
      currency: "KWD",
      plan_id: paymentData.plan.plan_id,
      success: false,
      environment: process.env.REACT_APP_ENVIRONMENT,
    });
  }, [id, userData]);

  const [isRedirecting, setIsRedirecting] = React.useState(false);

  const handlePay = async () => {
    setIsRedirecting(true);
    setTryagain(true);
    localStorageSet("executeRequestPaymentData", executeRequestPaymentData);
    await dispatch(
      executePaymentRequest(
        userData?.token?.access as string,
        executeRequestPaymentData
      )
    );
    setIsRedirecting(false);
  };

  const backToHome = () => {
    navigate("/checkout");
  };

  useEffect(() => {
    if (executePaymentResponse && tryagain) {
      window.location.href = executePaymentResponse?.payment_url as string;
    }
  }, [executePaymentResponse, tryagain]);

  useEffect(() => {
    // Block swipe back on mobile browser
    window.history.pushState(null, "", window.location.href);

    // Display a confirmation message when the user tries to leave the page
    window.addEventListener("beforeunload", function (e) {
      // Cancel the event
      e.preventDefault();
      // Chrome requires returnValue to be set
      e.returnValue = "";
    });

    return () => {
      // Remove the event listener when the component unmounts
      window.removeEventListener("beforeunload", function (e) {
        e.preventDefault();
        e.returnValue = "";
      });
    };
  }, []);

  return (
    <Layout title={t("PAYMENT_FAIL")} isLoading={paymentConfirmationLoading}>
      <Container
        sx={(theme) => ({
          padding: "16px 0px",
          flexShrink: 0,
          overflow: "hidden",
          backgroundColor: theme.palette.background.default,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "center",
          textAlign: "center",
          direction: isModeArabic ? "rtl" : "ltr",
          minHeight: "81vh",
        })}
      >
        <Box sx={{ paddingTop: "100px" }}>
          <SubscriptionErrorIcon />

          <DrawerHeading marginBottom="30px" marginTop="58px">
            {t("PAYMENT_FAIL_HEADING")}
          </DrawerHeading>
        </Box>

        <div>
          <LoadingButton
            disabled={isRedirecting}
            onClick={handlePay}
            variant="contained"
            sx={{
              // m: '20px',
              mt: "40px",
              width: "342px",
              height: "56px",
              maxWidth: "100%",
              display: "flex", // Add this style to make icon and text align horizontally
              alignItems: "center", // Vertically center the icon and text
            }}
            color="primary"
            loading={
              paymentConfirmationLoading ||
              executePaymentLoading ||
              isRedirecting
            }
          >
            {t("TRY_TO_PAY_AGAIN")}
          </LoadingButton>

          <div
            style={{
              display: "flex",
              justifyContent: "center", // Horizontally center
              alignItems: "center", // Vertically center
            }}
          >
            <LoadingButton
              onClick={backToHome}
              variant="text"
              sx={{
                color: "#556885",
                mt: "12px",
                width: "342px",
                height: "56px",
                maxWidth: "100%",
                display: "flex", // Add this style to make icon and text align horizontally
                alignItems: "center", // Vertically center the icon and text
                borderRadius: "48px",
              }}
            >
              {t("BACK_TO_CHECKOUT")}
            </LoadingButton>
          </div>
        </div>
      </Container>
    </Layout>
  );
};

export default PaymentError;
