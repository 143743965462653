import {
  Box,
  Button,
  Card,
  Chip,
  Grid,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import placeHolderRestaurantCover from "../../assets/illustrations/placeholder_restaurant_cover.png";
import placeHolderRestaurantLogo from "../../assets/illustrations/placeholder_restaurant_logo.png";

import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  actions,
  getRestaurantDetail,
  setCurrentPackageID,
} from "../../store/slices/restaurants";

// Components

// Styles

import { t } from "i18next";
import { Layout } from "../../layout/Layout2";
import { Package, Plan } from "../../types/restaurant";

import { ReactComponent as ArrowCircle } from "../../assets/icons/green_arrow_circle.svg";

import removeTrailingZeros from "../../utils/removeTrailingZeros";

import { Helmet } from "react-helmet";
import { ReactComponent as PackageIcon } from "../../assets/icons/package.svg";
import { LazyImage } from "../../components/LazyImage";
import axios from "../../utils/axios";

const RestaurantPlans: React.FC = () => {
  const dispatch = useAppDispatch();
  const params = useParams();
  const slug = params.slug;

  const { i18n } = useTranslation();
  const isArabic = i18n.language === "ar";

  const { isLoading, restaurant } = useAppSelector(
    (state) => state.restaurants
  );
  const { userData } = useAppSelector((state) => state.accounts);

  const generateInteractionID = () => {
    return "_" + Math.random().toString(36).substr(2, 9);
  };

  useEffect(() => {
    if (!slug) return;

    const userDataFromStorage = JSON.parse(
      localStorage.getItem("user") || "{}"
    );
    const token = userData?.token?.access || userDataFromStorage?.token?.access;

    if (!restaurant || !restaurant.plans)
      dispatch(getRestaurantDetail(slug as string, token));

    const key = `${slug}/view/`;
    const interactionKey = `${slug}/interaction_id`;

    const lastViewed = sessionStorage.getItem(key);
    const today = new Date().setHours(0, 0, 0, 0);
    const oneDay = 24 * 60 * 60 * 1000;

    if (
      lastViewed &&
      today - new Date(lastViewed).setHours(0, 0, 0, 0) < oneDay
    )
      return;

    const interactionID = generateInteractionID();
    sessionStorage.setItem(interactionKey, interactionID);

    const controller = new AbortController();
    const config = {
      headers: {
        "Content-type": "application/json",
        ...(token && { Authorization: `Bearer ${token}` }),
      },
      signal: controller.signal,
    };

    axios
      .post(
        `api/v1/impressions/restaurants/${slug}/view/`,
        { interaction_id: interactionID },
        config
      )
      .then(() => {
        sessionStorage.setItem(key, new Date().toISOString());
      })
      .catch((e) => {
        if (e.name !== "CanceledError") console.error(e);
      });

    return () => {
      if (controller) controller.abort();
    };
  }, [userData, slug]);

  const title: string =
    (isArabic ? restaurant?.name_arabic : restaurant?.name) || "";

  const logo = restaurant?.logo || placeHolderRestaurantLogo;
  const cover = restaurant?.cover || placeHolderRestaurantCover;

  return (
    <Layout title={title} restaurantLogo={logo} isLoading={isLoading}>
      <Helmet>
        <title>
          {isArabic
            ? `مطعم ${restaurant?.name_arabic} | اشتراكات للمطاعم الصحية`
            : `Restaurant ${restaurant?.name} | Eshtrakaat for Subscriptions Restaurants `}
        </title>
        <meta
          name="description"
          content={
            isArabic
              ? `يمكنك الإختيار من خطط مطعم ${restaurant?.name_arabic}  المتنوعة | اشتراكات للمطاعم الصحية`
              : `Choose from ${restaurant?.name} plans | Eshtrakaat for Subscriptions Restaurants`
          }
        />
      </Helmet>
      <LazyImage
        alt={title}
        sx={{
          aspectRatio: "21/9",
          width: "100%",
          maxWidth: "420px",
        }}
        image={cover}
        placeHolder={placeHolderRestaurantCover}
      />
      <LazyImage
        alt={title}
        image={logo}
        placeHolder={placeHolderRestaurantLogo}
        sx={(theme) => ({
          marginInlineStart: "17px",
          marginTop: "-40px",
          minWidth: "80px",
          maxWidth: "80px",
          minHeight: "80px",
          border: `2px solid ${theme.palette.background.default}`,
        })}
      />
      <Typography
        variant="h1"
        component="div"
        sx={{ marginTop: "10px", marginInlineStart: "17px" }}
      >
        {title}
      </Typography>
      <Grid container marginTop={"32px"} spacing={2}>
        {restaurant?.plans?.map((plan) => (
          <Grid item xs={12} sm={6} md={4} lg={3} xl={2} key={plan.id}>
            <PlanCard plan={plan} />
          </Grid>
        ))}
      </Grid>
    </Layout>
  );
};

const PlanCard = ({ plan }: { plan: Plan }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const selectRef = useRef<HTMLDivElement>(null);

  const { userData } = useAppSelector((state) => state.accounts);
  const token = userData?.token?.access;

  const [selectedPackId, setSelectedPackId] = useState<number>(
    plan.packages[0].id
  );
  const selectedPack = plan.packages.find((p) => p.id == selectedPackId);

  const { i18n } = useTranslation();
  const isArabic = i18n.language === "ar";

  const title = isArabic ? plan.title_arabic : plan.title;
  const description = (
    isArabic ? plan.description_arabic : plan.description
  )?.replace(", ", " • ");
  const meals = plan.meals.map((m) => (isArabic ? m.title_arabic : m.title));

  const packageTitle = (period: number) =>
    isArabic ? `${period} يوم` : `${period} days`;
  const offDays = (pack: Package) =>
    isArabic
      ? `راحة ${pack.off_days.map((d) => d.day_arabic).join(" و ")}`
      : `Off ${pack.off_days.map((d) => d.day).join(" and ")}`;

  const price = selectedPack?.discounted_price || selectedPack?.price || "";

  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (selectRef.current && selectRef.current.contains(e.target as Node)) {
      return; // Do nothing if the click came from within the Select
    }

    dispatch(setCurrentPackageID(selectedPackId));

    localStorage.setItem("selected_package_id", `${selectedPackId}`);

    dispatch(actions.setPlanDetail()); //TODO: plan detail should be part of the url

    localStorage.setItem("user", JSON.stringify(userData));
    navigate(`/restaurants/${params.slug}/${plan.id}`);

    const interactionIDKey = `${params.slug}/interaction_id`;
    const interactionID = sessionStorage.getItem(interactionIDKey);

    const config = {
      headers: {
        "Content-type": "application/json",
        ...(token && { Authorization: `Bearer ${token}` }),
      },
    };

    axios.post(
      `/api/v1/impressions/plans/${plan.id}/click/`,
      { interaction_id: interactionID },
      config
    );
  };

  return (
    <Card
      key={plan.id}
      sx={{ padding: "12px", cursor: "pointer" }}
      onClick={handleClick}
    >
      <Stack gap="20px">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Select
            onClick={(e) => e.stopPropagation()}
            ref={selectRef}
            value={selectedPackId}
            onChange={(e: { target: { value: any } }) => {
              localStorage.setItem(
                "selected_package_id",
                e.target.value as string
              );
              setSelectedPackId(Number(e.target.value));
            }}
            sx={(theme) => ({
              color: theme.palette.text.secondary,
            })}
            renderValue={() => {
              return (
                <Box
                  sx={{
                    display: "flex",
                    gap: 1,
                    alignItems: "center",
                    marginInlineEnd: "24px",
                  }}
                >
                  <PackageIcon />
                  <Typography component="div">
                    {packageTitle(selectedPack?.period || 0)}
                  </Typography>
                </Box>
              );
            }}
          >
            {plan.packages.map((pack) => (
              <MenuItem key={pack.id} value={pack.id}>
                {packageTitle(pack.period) + " - " + offDays(pack)}
              </MenuItem>
            ))}
          </Select>
          <Box>
            {selectedPack?.discounted_price && (
              <Typography
                variant="h2"
                component="span"
                sx={(theme) => ({
                  textDecoration: "line-through",
                  color: theme.palette.text.secondary,
                })}
              >
                {`(${removeTrailingZeros(selectedPack?.price || "")})`}
              </Typography>
            )}{" "}
            <Typography
              sx={(theme) => ({ color: theme.palette.primary.main })}
              variant="h2"
              component="span"
            >
              {`${removeTrailingZeros(price)} ${t("KWD")}`}
            </Typography>
          </Box>
        </Box>
        <Stack gap="17px">
          <Stack gap="9px">
            <Typography variant="h2" component="div">
              {title}
            </Typography>
            <Typography
              variant="h3"
              component="div"
              sx={(theme) => ({ color: theme.palette.text.secondary })}
            >
              {description}
            </Typography>
          </Stack>
          <Stack direction="row" gap="8px" flexWrap="wrap">
            {meals.map((m) => (
              <Chip
                sx={(theme) => ({
                  background: "rgba(241, 143, 1, 0.08)",
                  color: theme.palette.secondary.main,
                })}
                key={m}
                label={m}
              />
            ))}
          </Stack>
        </Stack>

        <Button
          disableRipple
          disableElevation
          variant="contained"
          sx={{
            borderRadius: "24px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "75%",
            background: "#ECF8F5",
            p: "8px",
            paddingInlineStart: "15px",
            "&:focus": {
              background: "#ECF8F5",
            },
            "&:hover": {
              background: "#ECF8F5",
            },
          }}
        >
          <Typography
            variant="h3"
            component="div"
            sx={(theme) => ({ color: theme.palette.primary.main })}
          >
            {t("VIEW_FOOD")}
          </Typography>
          <ArrowCircle
            style={{ transform: isArabic ? "rotate(180deg)" : "rotate(0)" }}
          />
        </Button>
      </Stack>
    </Card>
  );
};
export default RestaurantPlans;
